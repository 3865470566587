<template>
  <b-card
    title="规则列表"
  >
    <div class="custom-search">

      <!-- advance search input -->
      <b-row class="mb-2">
        <b-col md="3">
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            label-size="lg"
            label="分配人员"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="searchAdminName"
              placeholder="请输入分配人员"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" offset-md="3" class="tRight">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="pageRule(true)"
          >
            搜索
          </b-button>
        </b-col>
      </b-row>
      <b-button
        class="mb-2 mr-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        v-b-modal.modal-prevent-closing
        :to="{name: 'custody-ruleInfo',query:{edit: true}}"
        v-if="$checkButtonPermission('205001001')"
      >
        添加
      </b-button>
      <b-button
        class="mb-2 mr-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="removeRules"
        v-if="$checkButtonPermission('205001002')"
      >
        删除规则
      </b-button>
    </div>

    <!-- table -->
    <vue-good-table
      ref="ruleTable"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :sort-options="{
        enabled: false,
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageSize
      }"
      theme="my-theme"
      @on-row-click="onRowClick"
      @on-cell-click="onCellClick"
      @on-select-all="onSelectAll"
      @on-row-dblclick="onRowDoubleClick"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- 查看 -->
            <b-dropdown-item
              :to="{ name: 'custody-ruleInfo', query: { id: props.row.id , edit: false} }"
            >
              <span>
                <feather-icon icon="VoicemailIcon"/>
                <span class="align-middle ml-50">查看</span>
              </span>
            </b-dropdown-item>
            <!-- 编辑 -->
            <b-dropdown-item
              :to="{ name: 'custody-ruleInfo', query: { id: props.row.id , edit:true } }"
            >
              <span>
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="tCenter">
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="currentPageHandleChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput,BFormTextarea, BFormSelect, BRow, BCol,BCard,BButton,BModal,BDropdown,BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import request from '@/api/request'
export default {
  components: {
    BCard,VueGoodTable,BAvatar,BPagination,BFormGroup,BFormInput,BFormSelect,BRow,BCol,BButton,
    vSelect,BModal,BDropdown,BDropdownItem,BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      nameState: null,
      createTagForm:{
        name: "",
        note: "",
      },
      currentPage: 1,
      searchAdminName:"",
      pageSize: 5,
      total: 0,
      dir: false,
      roleOptions: [
        { label:'在线', value: "1" },
        { label: '离线', value: "2" },
        { label: '未激活', value: "3" },
      ],
      roleFilter:null,
      columns: [
        {
          label: '规则ID',
          field: 'id',
        },
        {
          label: '规则备注',
          field: 'note',
        },
        {
          label: '规则条件',
          field: 'ruleTypeNames',
        },
        {
          label: '分配人员',
          field: 'allocationUNames',
        },
        {
          label: '分配业务',
          field: 'businessTypeName',
        },
        {
          label: '机构名称',
          field: 'tenantName',
        },
        {
          label: '创建者名称',
          field: 'createUName',
        },
        {
          label: '创建时间',
          field: 'createTime',
        },
        {
          label: '操作',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    //this.$http.get('/good-table/advanced-search').then(res => {this.rows = res.data})
    this.pageRule()
  },
  methods: {
    resetTagCreateModal() {
      this.createTagForm.name = ''
      this.createTagForm.note = ''
    },
    onRowClick(params) {
      console.log(params.row)
    },
    onSelectAll(params){
      console.log('all')
    },
    onRowDoubleClick(params) {
      console.log(params)
    },
    onCellClick(params) {
      console.log('params',params)
      // if(params.column.field == 'action'){
      //   console.log(params.row)
      //   this.$router.push({name:"/label/configInfo",params:{id: params.row.id}});
      // }
    },
    currentPageHandleChange(currentPage){
      this.currentPage = currentPage
      this.pageRule()
    },
    pageRule(resetCurrentPage){
      if(resetCurrentPage)
        this.currentPage = 1
      request.get('tob/rule/bRule/page', {adminName:this.searchAdminName,pageSize: this.pageSize,currentPage: this.currentPage}).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.rows = res.data.data.data
          this.total = res.data.data.count
          console.log('total',this.total)
        }
      })
    },
    removeRules(){
      let ruleList = this.$refs['ruleTable'].selectedRows
      if(ruleList.length <= 0){
        this.$bvToast.toast("请选择要删除的规则",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }
      let ruleIdList = []
      for(let i=0;i<ruleList.length;i++){
        ruleIdList.push(ruleList[i].id)
      }
      request.delete('tob/rule/bRule/delete', {idList: ruleIdList}).then(res => {
        console.log('RES',res)
        if (res.data.success) {
            this.$bvToast.toast("操作成功",{
              title: `提示`,
              variant: 'success',
              solid: true
            })
          this.pageRule(true)
        }
      })
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>